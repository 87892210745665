import React from "react"
import styles from "./HomeProcess.module.css"
import HomeProcessItem from "./HomeProcessItem"
import Handshake from "../../../assets/img/handshake.svg"

const PROCESS = [
  {
    step: 1,
    description: "Describe your ideal designer",
  },
  {
    step: 2,
    description: "We pre-screen the qualified candidates",
  },
  {
    step: 3,
    description: "Do the visual screening with our smart tool",
  },
  {
    step: 4,
    description: "Get the shortlist and request a meeting",
  },
]

const HomeProcess = () => (
  <div className={styles.container} id="how-it-works">
    <div className={styles.title}>How it works</div>
    <div className={styles.processWrapper}>
      {PROCESS.map((item, index) => (
        <HomeProcessItem
          key={item.step}
          step={item.step}
          description={item.description}
          isLast={index === PROCESS.length - 1}
        />
      ))}
      <div className={styles.matchWrapper}>
        <div className={styles.icon}>
          <Handshake width="100%" height="100%" />
        </div>
        <div className={styles.matchText}>It&apos;s a match!</div>
      </div>
    </div>
  </div>
)

export default HomeProcess
