import React from "react"
import Auto from "../../../assets/img/icon_car.svg"
import House from "../../../assets/img/icon_house.svg"
import Lift from "../../../assets/img/icon_lift.svg"
import Watch from "../../../assets/img/icon_smart_watch.svg"
import Hotel from "../../../assets/img/icon_hotel.svg"
import Washing from "../../../assets/img/icon_washing.svg"
import Chair from "../../../assets/img/icon_chair.svg"
import Game from "../../../assets/img/icon_game.svg"
import styles from "./HomeIndustries.module.css"
import FeatureList from "../../common/FeatureList/FeatureList"

const IndustriesPage = () => (
  <div className={styles.container} id="industries">
    <div className={styles.title}>Industries we serve</div>
    <FeatureList
      isFeature={false}
      features={[
        {
          icon: <Auto width="80%" height="70px" />,
          text1: "Automotive",
        },
        {
          icon: <House width="100%" height="70px" />,
          text1: "Architecture",
        },
        {
          icon: <Game width="100%" height="70px" />,
          text1: "Gaming",
        },
        {
          icon: <Lift width="100%" height="70px" />,
          text1: "Elevators",
        },
        {
          icon: <Watch width="100%" height="70px" />,
          text1: "Consumer",
          text2: "electronics",
        },
        {
          icon: <Hotel width="100%" height="70px" />,
          text1: "Hospitality",
        },
        {
          icon: <Chair width="100%" height="70px" />,
          text1: "Furniture",
        },
        {
          icon: <Washing width="100%" height="70px" />,
          text1: "Home",
          text2: "appliances",
        },
      ]}
    />
  </div>
)

export default IndustriesPage
