import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames/bind"

import styles from "./HomeContact.module.css"

const cx = classnames.bind(styles)

const HomeContact = () => {
  const data = useStaticQuery(graphql`
    query {
      contactEurope: file(relativePath: { eq: "contact-europe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactAsia: file(relativePath: { eq: "contact-asia.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const CONTACTS = [
    {
      continent: "Europe",
      city: "Helsinki",
      phone: "+358 10 281 6820",
      email: "europe@creagent.com",
      image: data.contactEurope.childImageSharp.fluid,
    },
    {
      continent: "Asia",
      city: "Shanghai",
      phone: "+86 130 2310 7225",
      email: "asia@creagent.com",
      image: data.contactAsia.childImageSharp.fluid,
    },
  ]

  const ContactItem = ({ continent, city, phone, email, image, reversed }) => (
    <div className={cx(styles.content, { reversed })}>
      <div className={styles.textWrapper}>
        <div className={styles.continent}>{continent}</div>
        <div className={styles.city}>{city}</div>
        <div className={styles.subtext}>
          <a href={`tel:${phone}`}>{phone}</a>
        </div>
        <div className={styles.subtext}>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
      <div className={styles.image}>
        <Img fluid={image} style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  )
  return (
    <div className={styles.container} id="contact">
      <div className={styles.title}>Contact</div>
      {CONTACTS.map((contact, index) => (
        <ContactItem
          key={contact.city}
          continent={contact.continent}
          city={contact.city}
          phone={contact.phone}
          email={contact.email}
          image={contact.image}
          reversed={index % 2 === 1}
        />
      ))}
    </div>
  )
}

export default HomeContact
