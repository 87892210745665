import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./HomeMap.module.css"

const HomeMap = () => {
  const data = useStaticQuery(graphql`
    query {
      website: file(relativePath: { eq: "map.png" }) {
        childImageSharp {
          fluid(maxWidth: 1080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Img
          fluid={data.website.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
      </div>
      <div className={styles.text}>
        WE REACH OVER 1 MILLION QUALIFIED <br />
        DESIGNERS IN EUROPE AND ASIA <br /> WITH SMART SOURCING AND OPEN DATA
      </div>
    </div>
  )
}

export default HomeMap
