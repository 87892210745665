import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styles from "./HomeStory.module.css"

const HomeStory = () => {
  const data = useStaticQuery(graphql`
    query {
      website: file(relativePath: { eq: "story.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className={styles.container} id="learn-more">
      <div className={styles.bigText}>
        the future of design <br /> resourcing is here. <br />
        Hire and subcontract <br />
        from around the world.
      </div>
      <div className={styles.imageTextWrapper} id="about">
        <div className={styles.image}>
          <Img
            fluid={data.website.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        </div>
        <div className={styles.text}>
          We bring data and design together to match the best design talent to
          global companies. With unique visual matching powered by AI we help
          companies thrive with design.
          <div className={styles.read}>
            <Link to="/about">Read the story</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeStory
