import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/common/SEO/SEO"
import HomeContainer from "../components/home/HomeContainer/HomeContainer"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundAsian: file(relativePath: { eq: "background-asian.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundMale: file(relativePath: { eq: "background-male.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      backgroundFemale: file(relativePath: { eq: "background-female.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="application">
      <SEO />
      <HomeContainer data={data} />
    </div>
  )
}
