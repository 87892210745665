import React from "react"
import Pen from "../../../assets/img/pen.svg"
import Check from "../../../assets/img/check.svg"
import Pie from "../../../assets/img/pie.svg"
import styles from "./HomeFeature.module.css"
import FeatureList from "../../common/FeatureList/FeatureList"

export default () => (
  <div className={styles.container} id="why-creagent-is-different">
    <div className={styles.title}>Why Creagent is different</div>
    <FeatureList
      isFeature
      features={[
        {
          icon: <Pen width="100%" height="100%" />,
          text1: "One stop shop for all",
          text2: "kinds of design talent",
        },
        {
          icon: <Check width="100%" height="100%" />,
          text1: "High quality guaranteed",
          text2: "with domain expertise and AI",
        },
        {
          icon: <Pie width="100%" height="100%" />,
          text1: "At a fraction of the",
          text2: "cost of headhunters",
        },
      ]}
    />
  </div>
)
