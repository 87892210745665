import React from "react"
import styles from "./HomeProcessItem.module.css"

const HomeProcessItem = ({ step, description, isLast }) => (
  <div className={styles.container}>
    <div className={styles.stepWrapper}>
      <div className={styles.step}>{step}</div>
      <div className={styles.descriptionMobile}>{description}</div>
      {!isLast && <div className={styles.line} />}
      {isLast && (
        <>
          <div className={styles.line} /> <div className={styles.point} />
        </>
      )}
    </div>
    <div className={styles.descriptionDesktop}>{description}</div>
  </div>
)

export default HomeProcessItem
