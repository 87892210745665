/* eslint-disable no-param-reassign */
import React from "react"
import sample from "lodash.sample"

import Img from "gatsby-image"
import styles from "./HomeContainer.module.css"
import HomeFront from "../HomeFront/HomeFront"
import HomeDesign from "../HomeDesign/HomeDesign"
import HomeFeature from "../HomeFeature/HomeFeature"
import HomeFooter from "../HomeFooter/HomeFooter"
import HomeIndustries from "../HomeIndustries/HomeIndustries"
import HomeStory from "../HomeStory/HomeStory"
import HomeMap from "../HomeMap/HomeMap"
import HomeSnowflake from "../HomeSnowflake/HomeSnowflake"
import HomeProcess from "../HomeProcess/HomeProcess"
import HomeContact from "../HomeContact/HomeContact"

/**
 * Randomly shuffle an array
 * https://stackoverflow.com/a/2450976/1293256
 * @param  {Array} array The array to shuffle
 * @return {String}      The first item in the shuffled array
 */
const shuffle = array => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export default ({ data }) => {
  const bgAsian = {
    img: data.backgroundAsian.childImageSharp.fluid,
    objectPosition: "80% top",
  }
  const bgMale = {
    img: data.backgroundMale.childImageSharp.fluid,
    objectPosition: "80% 30%",
  }
  const bgFemale = {
    img: data.backgroundFemale.childImageSharp.fluid,
    objectPosition: "60% top",
  }
  const pair = shuffle([bgAsian, sample([bgMale, bgFemale])])
  const bgHeader = pair[0]
  const bgFooter = pair[1]

  return (
    <div className={styles.container} id="container">
      <div className={styles.image}>
        <Img
          fluid={bgHeader.img}
          style={{ height: "100%" }}
          imgStyle={{ objectPosition: bgHeader.objectPosition }}
        />
      </div>
      <HomeFront />
      <HomeStory />
      <HomeFeature />
      <HomeMap />
      <HomeSnowflake />
      <HomeIndustries />
      <HomeProcess />
      <HomeDesign />
      <HomeContact />
      <HomeFooter background={bgFooter} />
    </div>
  )
}
