import React from "react"
import classnames from "classnames/bind"
import styles from "./Feature.module.css"

const cx = classnames.bind(styles)

export default ({ icon, text1, text2, isFeature = true }) => (
  <div
    className={cx("container", {
      featureContainer: isFeature,
    })}
  >
    <div className={styles.image}>{icon}</div>
    <div className={styles.text}>
      {text1} <br /> {text2}
    </div>
  </div>
)
