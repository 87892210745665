import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styles from "./HomeDesign.module.css"

// const TITLE = "For designpeople by designpeople"

const HomeDesign = () => {
  const data = useStaticQuery(graphql`
    query {
      website: file(relativePath: { eq: "website.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={styles.container} id="design">
      {/* <div className={styles.title}>{TITLE.toUpperCase()}</div> */}
      <div className={styles.descriptionWrapper}>
        <div className={styles.lineWrapper}>
          <div className={styles.description}>
            Pre-screened professional <br className={styles.brDesktop} />{" "}
            designers <br className={styles.brMobile} /> shortlisted{" "}
            <br className={styles.brDesktop} /> in a visual way
          </div>
          <div className={styles.descriptionLine} />
        </div>
        <div className={styles.image}>
          <Img fluid={data.website.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  )
}

export default HomeDesign
