/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import { navigate } from "gatsby"

import Img from "gatsby-image"
import styles from "./HomeFooter.module.css"
import Button from "../../common/Button/Button"
import SocialMediaFooter from "../../common/SocialMediaFooter/SocialMediaFooter"

const FOOTER_TITLE_1 = "For designpeople"
const FOOTER_TITLE_2 = "by designpeople"

const HomeFooter = ({ background }) => {
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Img
          fluid={background.img}
          style={{
            width: "100%",
            height: "100%",
            opacity: ".6",
          }}
          imgStyle={{ objectPosition: background.objectPosition }}
        />
      </div>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{FOOTER_TITLE_1.toUpperCase()}</div>
        <div className={styles.title}>{FOOTER_TITLE_2.toUpperCase()}</div>
        <Button
          text="Find designers now"
          onClick={e => {
            e.preventDefault()
            navigate("/client_form")
          }}
          style={{ maxWidth: "200px", marginTop: "60px" }}
        />
      </div>
      <div className={styles.mediaWrapper}>
        <SocialMediaFooter />
      </div>
    </div>
  )
}

export default HomeFooter
