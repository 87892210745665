import React from "react"
import { Link, navigate } from "gatsby"
import styles from "./HomeFront.module.css"
import Button from "../../common/Button/Button"
import Header from "../../common/Header/Header"

// const slideToDesign = () => {
//   const design = document.getElementById("design")
//   const container = document.getElementById("container")
//   container.scrollTo({
//     top: design.offsetTop,
//     behavior: "smooth",
//   })
// }

const HomeFront = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.headliner}>
        <div className={styles.headlinerText}>FIND DESIGNERS.</div>
        <div className={styles.headlinerText}>SCREENED &amp;</div>
        <div className={styles.headlinerText}>SHORTLISTED.</div>
        <div className={styles.tagLine}>
          Easy, fast and cost-effective - <br /> the smart way of sourcing with
          AI.
        </div>
        <div className={styles.ctaWrapper}>
          <Button
            text="Get started"
            onClick={e => {
              e.preventDefault()
              navigate("/client_form")
            }}
            style={{ width: "125px", marginRight: "1em" }}
          />
          <Link to="/#learn-more" className={styles.link}>
            or learn more
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeFront
