import React from "react"
import Snowflake from "../../../assets/img/snowflake.svg"
import styles from "./HomeSnowflake.module.css"

const HomeSnowflake = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Creagent talent areas</div>
      <div className={styles.text}>
        <div className={styles.lineWrapper}>
          <div className={styles.description}>
            Creagent covers <br />{" "}
            <span style={{ color: "#e80000" }}>a wide spectrum</span>
            <br /> of different talent areas
          </div>
          <div className={styles.descriptionLine} />
        </div>
      </div>
      <div className={styles.image}>
        <Snowflake width="100%" height="100%" />
      </div>
    </div>
  )
}

export default HomeSnowflake
