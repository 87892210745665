import React from "react"
import styles from "./FeatureList.module.css"
import Feature from "../Feature/Feature"

export default ({ features, isFeature }) => (
  <div className={styles.container}>
    {features.map(feature => (
      <Feature
        isFeature={isFeature}
        key={feature.text1}
        icon={feature.icon}
        text1={feature.text1}
        text2={feature.text2}
        color={feature.iconColor}
      />
    ))}
  </div>
)
